//import {TOKEN} from "../redux/types/types";

interface HeadersData {
        [key:string]: string;
  }
  
  export default   function  headers() {
      const items: HeadersData = { 'Content-Type': 'multipart/form-data; boundary=---------------------------974767299852498929531610575','withCredentials':'true',"Access-Control-Allow-Origin": "http://localhost:3000"};
      const  token =  localStorage.getItem('token');
      if (token) {
           items.Authorization = `Bearer ${token}`;
      }
      console.log(items)
      return items;
    }