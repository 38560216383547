import AXIOS from "../config/Axios";
import Prefix from "../config/ApiPrefix"
import headers from "../helpers/headers";
import { Client, Credentials, DataClient } from '../redux/types/types';

const Clients = {
  register(data:DataClient){
    return AXIOS.post(`${Prefix.api}/client/register`,{...data});

  },
  getProfile(id: number){
    return AXIOS.get(`${Prefix.api}/client/${id}`,{headers:headers()});
  },
  updateProfile(data: FormData,id:number){
    return AXIOS.post(`${Prefix.api}/client/update/${id}`,data,{headers:headers()});
  },

};


export default Clients;