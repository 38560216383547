import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Section } from "../components";
import { ListingItemContainer } from "./index";
import { getFeaturedList } from "../redux/actions/propertiesAction";
import { Announcement, Announcements, RealEstate } from "../redux/types/types";
import RealEstateServices from "../services/RealEstate";
import { useParams } from "react-router";
import { getAnnouncementsList } from "../redux/actions/AnnouncementsActions";
import { RootState } from "../redux/store";

const FeaturedListingContainer = () => {
  const dispatch = useDispatch();

  const announcements:Announcements = useSelector((state:RootState) => state.announcements);
  const { id }:any = useParams();


  useEffect(() => {
    dispatch(getAnnouncementsList());
  }, []);




  return (
    <Section bgColor="--bs-light">
      <Section.InnerContainer>
        <Section.Header>
          <Section.Title>Our Featured Listing</Section.Title>
        </Section.Header>
        <Section.Content>
          {announcements?.announcements?.map((announcement:Announcement) => (
            <ListingItemContainer width="auto" key={announcement.id}  announcement={announcement} />
          ))}
        </Section.Content>
        <Section.Footer>
          <Section.Button>More Listing</Section.Button>
        </Section.Footer>
      </Section.InnerContainer>
    </Section>
  );
};

export default FeaturedListingContainer;
