import React, {Fragment, useEffect, useState} from "react";
import { HeaderContainer, FooterContainer } from "../containers";
import { Login, Form } from "../components";
//import Clients from "../services/Clients";
//import Auth from "../services/Auth";
import {login} from "../redux/actions/authActions"
import {Credentials, TOKEN} from "../redux/types/types";
import {connect, useDispatch, useSelector, useStore} from "react-redux";
import {useHistory} from 'react-router-dom';
//import bcrypt from "bcrypt-nodejs";
//import {State} from "../redux/reducers/authReducer";
import {RootState} from "../redux/store";
import {CircularProgress} from "@mui/material";

const initialState:Credentials={
  email:"",
  password:""
}
type Error={
  email:string,
  password:string,
  errorCredentials:string
}
const initError={
  email:"",
  password:"",
  errorCredentials:""
}


const Loginn = () => {
  // eslint-disable-next-line no-restricted-globals,no-native-reassign
  const [formValues, setFormValues] = useState<Credentials>(initialState);
  const [formErrors, setFormErrors] = useState<Error>(initError);
  const store=useStore();
  let {loading,user,errorMessage} = useSelector((state: RootState) => state.auth);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormValues({...formValues, [e.target.name]: e.target.value})
    setFormErrors(validate(formValues));

  }
  // eslint-disable-next-line no-restricted-globals
  let history = useHistory();

  const dispatch = useDispatch();

  const handleSubmit =async  (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    console.log(errorMessage)
    setFormErrors(validate(formValues));
   
        await dispatch(login(formValues));
        history.push("/");

    }

  

  const validate = (values: Credentials) => {
    let errors: Error = {
      email: "",
      password: "",
      errorCredentials: ""
    };
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (values.email === "") {
      errors.email = "Cannot be blank";
    } else if (!regex.test(values.email)) {
      errors.email = "Invalid email format";
    }
    if (values.password === "") {
      errors.password = "Cannot be blank";
    } else if (values.password.length < 4) {
      errors.password = "Password must be more than 4 characters";
    }
   



    return errors;
  };

  return (
      <Fragment>
        <HeaderContainer bg="false" />
        <Login>
          <Login.Container>
            <Login.Content>
              <Login.Header>
                <Login.Title>Login</Login.Title>
              </Login.Header>
              <Login.InnerContent>
                <Form onSubmit={(e:any)=>handleSubmit(e)}>
                  <Form.FormGroup>
                    <Form.Label>Email</Form.Label>
                    <Form.Input type="text" name="email" value={formValues.email} onChange={handleChange} />
                  </Form.FormGroup>
                  {formErrors.email && (
                      <span style={{color:"red",fontWeight:"bold"}}>{formErrors.email}</span>
                  )}
                  <Form.FormGroup>
                    <Form.Label>Password</Form.Label>
                    <Form.Input type="password" name="password"  value={formValues.password} onChange={handleChange} />
                  </Form.FormGroup>
                  {formErrors.password && (
                      <span style={{color:"red",fontWeight:"bold"}}>{formErrors.password}</span>
                  )}
                  <Form.FormGroup>
                  {
                    loading?<CircularProgress/>:<Form.SubmitInput type="submit" value="Login" />
                  }
                    
                  </Form.FormGroup>
                  {errorMessage && (
                      <span style={{color:"red",fontWeight:"bold"}}>{errorMessage}</span>
                  )}

                </Form>
              </Login.InnerContent>
              <Login.Footer>
                <Login.Text>
                  <Login.Anchor to="/forgot-password">
                    Forgot Password ?
                  </Login.Anchor>
                </Login.Text>
                <Login.Text>
                  Don't have an Account ?{" "}
                  <Login.Anchor to="/signup">Sign Up</Login.Anchor>
                </Login.Text>
              </Login.Footer>
            </Login.Content>
          </Login.Container>
        </Login>
        <FooterContainer />
      </Fragment>
  );
      }
export default Loginn
