import {
  GET_PROPERTIES,
  GET_FEATURED_PROPERTIES,
  GET_PROPERTY,
} from "../constants/propertyConstants";
import properties from "../../dev-data/listing";
import { Dispatch } from "redux";

export const getPropertyList = () => async (dispatch:any) => {
  dispatch({ type: GET_PROPERTIES, payload: properties });
};

export const getFeaturedList = () => async (dispatch:any) => {
  const featured = properties
    .filter((property) => property.featured === true)
    .slice(0, 6);
  dispatch({ type: GET_FEATURED_PROPERTIES, payload: featured });
};

export const getProperty = (id:number) => async (dispatch:any) => {
  const property = properties.find((property) => property.id === +id);
  dispatch({ type: GET_PROPERTY, payload: property });
};
