import React, { useState } from "react";
import links from "../constants/routes/nav-links";
import { HeaderWrapper, Banner, Jumbotron } from "../components";
import AvatarMenu from "../components/AvatarMenu/AvatarMenu";
import { AdvancedSearchContainer, SideNavigationContainer } from "./index";
import { Avatar } from "@mui/material";
import { USER } from "../redux/types/types";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutSession } from "../redux/actions/authActions";

const HeaderContainer = ({ bg, source }: any) => {
  const [sideNavShown, setSideNavShown] = useState(false);
  const [sideNavHidden, setSideNavHidden] = useState(true);
  const [fixed, setFixed] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const changeBackgroundColorAndPosition = () => {
    if (window.pageYOffset > 100) {
      setFixed(true);
    } else {
      setFixed(false);
    }
  };

  window.addEventListener("scroll", changeBackgroundColorAndPosition);

  const handleSideNavigation = () => {
    setSideNavHidden((prevState) => !prevState);
    setSideNavShown((prevState) => !prevState);
  };
  return (
    <Banner >
      <HeaderWrapper bg={bg} fixed={fixed}>
        <HeaderWrapper.Container>
          <HeaderWrapper.Title bg={bg}>
            <HeaderWrapper.Link bg={bg} fixed={fixed} to="/">
              Real Home
            </HeaderWrapper.Link>
          </HeaderWrapper.Title>
          <HeaderWrapper.LinksContainer>
            <HeaderWrapper.List links="links">
              {links.map((link) => (
                <HeaderWrapper.Item key={link.to}>
                  <HeaderWrapper.Anchor bg={bg} fixed={fixed} to={`${link.to}`}>
                    {link.name}
                  </HeaderWrapper.Anchor>
                </HeaderWrapper.Item>
              ))}
              {!localStorage.getItem(USER) ? (
                <>
                  <HeaderWrapper.Item key="login">
                    <HeaderWrapper.Anchor bg={bg} fixed={fixed} to="/login">
                      Login
                    </HeaderWrapper.Anchor>
                  </HeaderWrapper.Item>
                </>
              ) : (
                <></>
              )}
              {localStorage.getItem(USER) ? (
                <>
                  <HeaderWrapper.Item
                    onClick={() => {
                      dispatch(logoutSession());
                      localStorage.clear();
                      history.push("/login");
                    }}
                    key="logout"
                  ></HeaderWrapper.Item>
                  
                    {`${
                      JSON.parse(localStorage.getItem(USER) || "{}").first_name
                    }_${
                      JSON.parse(localStorage.getItem(USER) || "{}").last_name
                    }`}
                    <AvatarMenu />{" "}
                </>
              ) : (
                <></>
              )}
            </HeaderWrapper.List>

            <HeaderWrapper.List side="side">
              <HeaderWrapper.Item>
                <HeaderWrapper.Button onClick={handleSideNavigation}>
                  <HeaderWrapper.Icon name="fa fa-bars" />
                </HeaderWrapper.Button>
              </HeaderWrapper.Item>
            </HeaderWrapper.List>
          </HeaderWrapper.LinksContainer>
        </HeaderWrapper.Container>
      </HeaderWrapper>
      
    
      
      <SideNavigationContainer
        sideNavShown={sideNavShown}
        sideNavHidden={sideNavHidden}
        setSideNavHidden={setSideNavHidden}
        setSideNavShown={setSideNavShown}
      />
    </Banner>
  );
};

export default HeaderContainer;
