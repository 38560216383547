import { AgencyActionType, GET_AGENCIES } from "../constants/AgencyConstants";
import { Agencies } from "../types/types";

const initStateAgency:Agencies={
    agencies:[]
}

export const agencyListReducer = (state =initStateAgency, action:AgencyActionType) => {
    switch (action.type) {
      case GET_AGENCIES:
        return {...state,
            agencies: action.payload };
      default:
        return state;
    }
  };