import { Listing } from "../components";
import { Announcement } from "../redux/types/types";
interface Props{
  announcement:Announcement,
  width?:string
}

const ListingItemContainer = (props:Props) => {

  
  

  return (
    <Listing width="50%">
      <Listing.Top>
        <Listing.TopItem>
          <Listing.Image source={`${props?.announcement?.image}`} />
          <Listing.TopItemContainer>
            <Listing.TopItemInfo>
              <Listing.Icon></Listing.Icon>
              <Listing.Text location>{props.announcement?.real_estate?.address}</Listing.Text>
            </Listing.TopItemInfo>
          </Listing.TopItemContainer>
        </Listing.TopItem>
      </Listing.Top>
      <Listing.Bottom>
        <Listing.BottomItem>
          <Listing.Title>
            <Listing.Anchor to={`/property/${props?.announcement?.id}`}>
              {props?.announcement?.title}
            </Listing.Anchor>
          </Listing.Title>
          <Listing.Price>Ksh {props?.announcement?.real_estate?.price}</Listing.Price>
          <Listing.Text description>
            {props?.announcement?.body}
          </Listing.Text>
          <Listing.Button>
            <Listing.Anchor to={`/property/${props?.announcement?.id}`}>
              Details
            </Listing.Anchor>
          </Listing.Button>
          <Listing.AgentContainer>
            <Listing.AgentImageContainer>
              <Listing.Image
                src={`http://localhost:8000/storage/images/${props.announcement?.real_estate.agency.image}`}
                profile="true"
              />
            </Listing.AgentImageContainer>
            <Listing.AgentName>
              <Listing.Anchor to={`/agents/`}>
               <h2>{props.announcement?.real_estate.agency.name}</h2>
              </Listing.Anchor>
            </Listing.AgentName>
          </Listing.AgentContainer>
        </Listing.BottomItem>
      </Listing.Bottom>
    </Listing>
  );
};

export default ListingItemContainer;
