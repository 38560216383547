import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  HeaderContainer,
  AgencyItemContainer,
  FooterContainer,
} from "../containers";
import { Section } from "../components";
import { RootState } from "../redux/store";
import { Agencies, Agency } from "../redux/types/types";
import { getAgencyList } from "../redux/actions/AgencyActions";

const Agents = () => {
  const dispatch = useDispatch();

  const agencies:Agencies= useSelector((state:RootState) => state.agencyList);
  useEffect(() => {
    dispatch(getAgencyList());
  }, [agencies, dispatch]);
  return (
    <>
      <HeaderContainer bg="false" />
      <Section bgColor="--bs-fade-info">
        <Section.InnerContainer>
          <Section.Header>
            <Section.Title>Our Agencies</Section.Title>
            <Section.Text>List of our most trusted agents</Section.Text>
          </Section.Header>
          <Section.Content>
            {agencies.agencies.map((agency:Agency) => (
              <AgencyItemContainer agency={agency} />
            ))}
          </Section.Content>
        </Section.InnerContainer>
      </Section>
      <FooterContainer />
    </>
  );
};

export default Agents;
