/* eslint-disable no-octal-escape */

import React, { useRef, useState,useEffect } from "react";
import { useSelector ,useDispatch} from "react-redux";
import { Profile, Form } from "../components";
import { Item } from "../components/footer/styles/footer";

import { getProfile, updateProfile } from "../redux/actions/authActions";
import { Client, USER } from "../redux/types/types";

const ProfileContainer = () => {

 const {user}=useSelector((state:any)=>state.auth);
  const [client,setClient]=useState<Client>(user);
  const [file, setFile] = useState<File|null>();
  const dispatch=useDispatch();

  const handleChange=(e:React.ChangeEvent<HTMLInputElement>)=>{
    setClient({...client,[e.target.name]:e.target.value});
    console.log(client);
  }
  const handleChangeImage=async(e:React.ChangeEvent<HTMLInputElement>)=> {
    //let url = URL.createObjectURL(e.target.files[0]);
    setFile(e?.currentTarget?.files?.item(0));
    /*const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob); 
      reader.onloadend = () => {
        const base64data = reader.result; 
        console.log(base64data);  
        resolve(base64data);
      }
    });*/
  }

  /*const handleChangeImage=async(e: React.ChangeEvent<HTMLInputElement>)=>{
    if (e?.target?.files?.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0]
      });
      console.log(image)
    }

  }*/
 


  const UpdateProfile=(e:React.ChangeEvent<HTMLInputElement>)=>{
    e.preventDefault();
    console.log(file);
    if(file)
      dispatch(updateProfile(client,file));
  }
 
  return (
    <Profile>
      <Form  onSubmit={UpdateProfile}>
    
        <Profile.Bio>
          <Profile.BioTop>
          
          <Form.FormGroup>
              <Form.Label>Cin</Form.Label>
              <Form.Input type="text" name="cin" value={client.cin}  onChange={handleChange}  />
  
            </Form.FormGroup>
            <Form.FormGroup>
              <Form.Label>First_Name</Form.Label>
              <Form.Input type="text" name="first_name" value={client.first_name} onChange={handleChange}  />
            </Form.FormGroup>
            <Form.FormGroup>
              <Form.Label>last_name</Form.Label>
              <Form.Input type="text" name="last_name" value={client.last_name} onChange={handleChange}  />
            </Form.FormGroup>
            <Form.FormGroup>
              <Form.Label>Email</Form.Label>
              <Form.Input type="text" name="email"  value={client.email} onChange={handleChange} />
            </Form.FormGroup>
            <Form.FormGroup>
              <Form.Label>Phone_Number</Form.Label>
              <Form.Input type="text" name="phone_number" value={client.phone_number} onChange={handleChange}  />
            </Form.FormGroup>
            <Form.FormGroup>
              <Form.Label>Address</Form.Label>
              <Form.Input type="text" name="address" value={client.address} onChange={handleChange}  />
            </Form.FormGroup>
            <Form.FormGroup>
              <Form.Label>Region</Form.Label>
              <Form.Input type="text" name="region" value={client.region.region} onChange={handleChange}  />
            </Form.FormGroup>
            <Form.FormGroup>
              <Form.Label>Image</Form.Label>
              <Form.Input type="file"   onChange={(e:any)=>handleChangeImage(e)}/>

            </Form.FormGroup>
          </Profile.BioTop>
          <Profile.BioBottom>
            <Form.FormGroup>
              <Form.Label>About</Form.Label>
              <Form.TextArea name="" id="" cols="30" rows="10"></Form.TextArea>
            </Form.FormGroup>
          </Profile.BioBottom>
        </Profile.Bio>
        <Profile.Social>
          <Profile.SocialHeader>
            <Profile.Title>Social Accounts</Profile.Title>
          </Profile.SocialHeader>
          <Profile.SocialContent>
            <Form.FormGroup>
              <Form.Label for="">Facebook</Form.Label>
              <Form.Input type="text" className="form-input" />
            </Form.FormGroup>
            <Form.FormGroup>
              <Form.Label for="">Twitter</Form.Label>
              <Form.Input type="text" className="form-input" />
            </Form.FormGroup>
            <Form.FormGroup>
              <Form.Label for="">Instagram</Form.Label>
              <Form.Input type="text" className="form-input" />
            </Form.FormGroup>
            <Form.FormGroup>
              <Form.Label for="">Linkedin</Form.Label>
              <Form.Input />
            </Form.FormGroup>
          </Profile.SocialContent>
          <Form.FormGroup>
            <Form.SubmitInput  value="Save Changes"  />
          </Form.FormGroup>
        </Profile.Social>
        
      </Form>
    </Profile>
  );
};

export default ProfileContainer;
