import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import React, { ReactNode, useState } from "react";
import { Property } from "../components";
import Map from "../components/Map/Map";
import {Announcement} from "../redux/types/types";
 interface Props{
  announcement:Announcement|undefined
}
export const PropertGallery = ( {announcement}:Props) => {
    return (
        <Property.Gallery>
            <Property.ImageContainer>
                <Property.Image source={announcement?.image1} />
            </Property.ImageContainer>
            <Property.ImageContainer>
                <Property.Image source={announcement?.image2} />
            </Property.ImageContainer>
            <Property.ImageContainer>
                <Property.Image source={announcement?.image3} />
            </Property.ImageContainer>
            <Property.ImageContainer>
                <Property.Image source={announcement?.image4} />
            </Property.ImageContainer>
        </Property.Gallery>
    );
};

export const PropertyFeatures = ({ features }:any) => {
  const [featuresShown, setContentShown] = useState(false);

  const contentHandler = () => {
    setContentShown((previousState) => !previousState);
  };

  return (
    <Property.Info>
      <Property.InfoHeader onClick={contentHandler}>
        <Property.InfoTitle>Details and Features</Property.InfoTitle>
        <Property.Icon
          name={featuresShown ? "fas fa-chevron-up" : "fas fa-chevron-down"}
          info></Property.Icon>
      </Property.InfoHeader>
     
    </Property.Info>
  );
};

/*export const PropertyAmenities = ({ amenities }:any) => {
  const [amenitiesShown, setContentShown] = useState(false);

  const contentHandler = () => {
    setContentShown((previousState) => !previousState);
  };

  return (
    <Property.Info>
      <Property.InfoHeader onClick={contentHandler}>
        <Property.InfoTitle>Amenities</Property.InfoTitle>
        <Property.Icon
          name={amenitiesShown ? "fas fa-chevron-up" : "fas fa-chevron-down"}
          info></Property.Icon>
      </Property.InfoHeader>
      <Property.InfoContent contentShown={amenitiesShown}>
        {amenities.map((amenity:any) => (
          <Property.InfoItem key={amenity}>
            <Property.Text>{amenity}</Property.Text>
          </Property.InfoItem>
        ))}
      </Property.InfoContent>
    </Property.Info>
  );
};*/

export const PropertyAddress = () => {
  const [addressShown, setContentShown] = useState(false);

  const contentHandler = () => {
    setContentShown((previousState) => !previousState);
  };
  return (
    <Property.Info>
      <Property.InfoHeader onClick={contentHandler}>
        <Property.InfoTitle>Address</Property.InfoTitle>
        <Property.Icon
          name={addressShown ? "fas fa-chevron-up" : "fas fa-chevron-down"}
          info></Property.Icon>
                <Property.InfoContent block="true" contentShown={addressShown} >
                   </Property.InfoContent>


      </Property.InfoHeader>
      
    </Property.Info>
  );
};
export const PropertyDescription = ({ description }:any) => {
  const [descriptionShown, setContentShown] = useState(false);

  const contentHandler = () => {
    setContentShown((previousState) => !previousState);
  };
  return (
    <Property.Info>
      <Property.InfoHeader onClick={contentHandler}>
        <Property.InfoTitle>Property Description</Property.InfoTitle>
        <Property.Icon
          name={descriptionShown ? "fas fa-chevron-up" : "fas fa-chevron-down"}
          info></Property.Icon>
      </Property.InfoHeader>
      <Property.InfoContent block="true" contentShown={descriptionShown}>
        <Property.Text>{description}</Property.Text>
      </Property.InfoContent>
    </Property.Info>
  );
};
