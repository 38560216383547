import { CSSProperties, Fragment, memo, useCallback, useEffect, useState } from "react";
import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from '@react-google-maps/api';
import {} from "google-map-react";
const containerStyle:CSSProperties | undefined = {
  width: '1000',
  height: '500px'
};

const center: google.maps.LatLng | google.maps.LatLngLiteral  = {
  lat: -3.745,
  lng: -38.523
};

interface MarkerItem{
  id:number,
  name:string|undefined,
  position: google.maps.LatLng | google.maps.LatLngLiteral  ,
}
interface MapProps{
  name:string|undefined,
  lat:number ,
  lng:number 
  
}
const initProp:MapProps={
   name:"",
   lat: -3.745,
   lng: -38.523
}

function Map(prop:MapProps) {

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyA4s2BhVaaDw2f_qjj40adKotWnlmw6tS8"
  })

  const [mapRef, setMapRef] = useState<google.maps.Map | null | undefined>();
  const [markerMap, setMarkerMap] = useState<google.maps.MVCObject >();
  const [center, setCenter] = useState<google.maps.LatLngLiteral>({ lat: 44.076613, lng: -98.362239833 });
  const [zoom, setZoom] = useState(5);
  const [clickedLatLng, setClickedLatLng] = useState<google.maps.LatLngLiteral>();
  const [infoOpen, setInfoOpen] = useState(false);
  
    
useEffect(()=>{
  console.log(prop);
})
 

  const loadHandler = (map:google.maps.Map) => {
    // Store a reference to the google map instance in state
    setMapRef(map);
 
  };

 

  
  const renderMap = () => {
    return (
      <>
        <GoogleMap
          // Do stuff on map initial laod
          onLoad={loadHandler}
          // Save the current center position in state
          // Save the user's map click position
          onClick={(e:google.maps.MapMouseEvent) => setClickedLatLng(e.latLng?.toJSON())}
          center={new google.maps.LatLng( 44.076613,-98.362239833)}
          zoom={zoom}
          mapContainerStyle={{
            height: "70vh",
            width: "100%"
          }}
        >
            
                <Marker
                  position={new google.maps.LatLng(prop.lat,prop.lng)}
                >
                </Marker>
              
      
          

        
        </GoogleMap>

   
      </>
    );
  }
  return isLoaded ? renderMap() : null;
}

export default Map