import {Form, Signup} from "../components";
import React, {Fragment, useState} from "react";
import Auth from "../services/Auth";
import {HeaderContainer} from "../containers";


const ResetPassword=()=> {

    type FormData = {
        email: string
        password: string,
        password_confirmation: string
    }
    const Reset: FormData = {
        email: '',
        password: '',
        password_confirmation: ''
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setReset({...reset, [e.target.name]: e.target.value})
    }
    const [reset, setReset] = useState<FormData>(Reset)

    const token = new URLSearchParams(window.location.search).get("token");
    const email = reset.email;
    const password = reset.password;
    const password_confirmation = reset.password;
    const credentials = {
        'token': token,
        'email': email,
        'password': password,
        'password_confirmation': password_confirmation
    }
    const Submit = async (e: React.FormEvent<HTMLFormElement>) => {
        console.log(credentials);
        e.preventDefault();
        try {
            const res = await Auth.resetPassword(credentials);
            console.log(res);
            console.log('password changed')
        } catch (e) {
            throw e;
        }
    };
    return (
        <Fragment>
                <Signup.Container>
                    <Signup.Content>
                        <Signup.Header>
                            <Signup.Title>ResetPassword</Signup.Title>
                        </Signup.Header>
                        <Signup.InnerContent>
                            <Form onClick={(e:any)=>Submit(e)}>
            <Form.FormGroup>
                <Form.Label>Email</Form.Label>
                <Form.Input name='email' onChange={handleChange} type="text"/>
            </Form.FormGroup>
            <Form.FormGroup>
                <Form.Label>Password</Form.Label>
                <Form.Input name='password' onChange={handleChange} type="password" />
            </Form.FormGroup>
            <Form.FormGroup>
                <Form.Label>Password Confirmation</Form.Label>
                <Form.Input name='password_confirmation' onChange={handleChange} type="password" />
            </Form.FormGroup>
                            <Form.FormGroup>
                                <Form.SubmitInput type="submit" value="Reset"  />
                            </Form.FormGroup>
                            </Form>

                        </Signup.InnerContent>

                    </Signup.Content>
                </Signup.Container>
        </Fragment>
    );
}
export default ResetPassword;