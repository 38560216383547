import { useDispatch, useSelector } from "react-redux";
import {
  HeaderContainer,
  ListingItemContainer,
  AdvancedSearchContainer,
  FooterContainer,
} from "../containers";
import { Section } from "../components";
import { Announcement, RealEstate } from "../redux/types/types";
import React, { useEffect, useState } from "react";

import RealEstateServices from "../services/RealEstate";
import Pagination from "../components/Pagination/Pagination";
import SearchInput from "../components/SearchBar/SearchInput";
import SearchResult from "./searchResults";


const Listing = () => {
  
 
  


  
const [annnoucements,setAnnouncements]=useState<Announcement[]>([]);
const [currentPage, setCurrentPage] = useState(1);
const [announcementsPerPage] = useState(6);
const [query,setQuery] = useState("");
  const [filteredAnnouncements,setFilteredAnnouncement]=useState<Announcement[]>([])
const indexOfLastPost = currentPage * announcementsPerPage;
const indexOfFirstPost = indexOfLastPost - announcementsPerPage;
const currentAnnouncements = annnoucements?.slice(indexOfFirstPost, indexOfLastPost);
// Change page
const callback=(query:string)=>{
   let filterAnnouncements=currentAnnouncements.filter((announcement:Announcement)=> announcement.title===query)
   setFilteredAnnouncement(filterAnnouncements)

}

const paginate = (pageNumber:number) => setCurrentPage(pageNumber);
useEffect(()=>{

  RealEstateServices.getAnnouncements().then(res=>{
    console.log(res.data);
    setAnnouncements(res.data);
  }).catch(err=>console.log(err))


},[annnoucements])
  return (
    <>
      <HeaderContainer bg="false" />
      <Section bgColor="--bs-fade-info">
        <Section.InnerContainer>
          <Section.Flex>
            <Section.FlexItem  width="30%"  >
              <Section.Shadow>
                 <AdvancedSearchContainer/>
                  </Section.Shadow>
            </Section.FlexItem>
            <Section.FlexItem width="65%" >
              <Section.Title>Our Property List</Section.Title>
              <Section.Content>
              <SearchResult elements={currentAnnouncements} />
              </Section.Content>
              <Section.Footer>
              <Pagination
             postsPerPage={announcementsPerPage}
              totalPosts={annnoucements.length}
             paginate={paginate}
      />
              </Section.Footer>
            </Section.FlexItem>
          </Section.Flex>
        </Section.InnerContainer>
      </Section>

      
      <FooterContainer />
    </>
  );
};

export default Listing;
