import {LOGIN_SET_USER, LOGIN_SET_LOADING, LOGIN_LOGOUT, ActionType, LOGIN_SET_ERRORMESSAGE} from "../constants/authConstants";
import {Client, USER} from "../types/types";

export interface State{
    loading:boolean,
    user:Client,
    errorMessage: String,
}
const initialState:State={
    loading:false,
    user:JSON.parse(localStorage.getItem(USER)||'{}'),
    errorMessage:""
}
export const authReducer=(state:State=initialState , action:ActionType)=> {
    switch (action.type) {
        case LOGIN_SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case LOGIN_SET_USER:
            return {
                ...state,
                loading: false,
                user: action.payload,
                errorMessage:""
            };
        case LOGIN_LOGOUT:
            return  {...state,
                loading:false,
                user: {},
                errorMessage:""
            };
         case LOGIN_SET_ERRORMESSAGE:
             return{...state,
                loading:false,
                user:{},
                errorMessage:action.payload

             }   

        default:
            return state
    }
}