import React, {Fragment, useEffect, useState} from "react";
import { HeaderContainer, FooterContainer } from "../containers";
import { Forgot, Form } from "../components";
import * as Yup from 'yup';
import {CircularProgress} from "@mui/material";
import Auth from "../services/Auth";
const validationSchema = Yup.object().shape({

  email: Yup.string().required("Email is required").email("Email is invalid"),
  password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters")
      .max(40, "Password must not exceed 40 characters"),
});
const Forgott = () => {

    const [formErrors, setFormErrors] = useState({email:""});
    const [isSubmitting, setIsSubmitting] = useState(false);
  const [email,setEmail]=useState("")
  const [loading,setLoading]=useState(false);
  const [finished,setFinished]=useState(false);
const SubmitForm=()=>{
    setFormErrors(validate(email));
    setIsSubmitting(true);
}
  const handleChange=(e:React.ChangeEvent<HTMLInputElement>)=>{
    setEmail(e.target.value)
  }
    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmitting) {
            SubmitForm();
        }
    }, [formErrors]);
const validate=(email:string)=>{
      let errors={
          email:""
      };
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!email) {
        errors.email = "Cannot be blank";
    } else if (!regex.test(email)) {
        errors.email = "Invalid email format";
    }
    return errors;

}
  const handleSubmit=async(e:React.ChangeEvent<HTMLInputElement>)=>{
    setLoading(true);
    e.preventDefault();
    SubmitForm();
    try {
      await Auth.sendLink({email});
      setLoading(false)
      setFinished(true);

    } catch(err:any){
      setLoading(false)
      setFinished(true);

      throw err;

    }
    setLoading(false)
    setFinished(false);
  }
  // @ts-ignore
  return (
    <Fragment>
      <HeaderContainer bg="false" />
      <Forgot>
        <Forgot.Container>
          <Forgot.Content>
            <Forgot.Header>
              <Forgot.Title>Reset Your Password</Forgot.Title>
            </Forgot.Header>
            <Forgot.InnerContent>
               <Form onSubmit={handleSubmit}>
                    <Form.FormGroup>
                      <Form.Label>Email</Form.Label>
                      <Form.Input type="text" value={email} onChange={handleChange}/>
                    </Form.FormGroup>
                    <Form.FormGroup>
                        {formErrors.email && (
                            <span style={{color:"red",fontWeight:"bold"}}>{formErrors.email}</span>
                        )}
                      <Form.SubmitInput disabled={false} id="email" value="Send Reset Token"/>

                    </Form.FormGroup>
                    {(!formErrors.email&&loading) ? <CircularProgress/> : <></>}

                  </Form>


            </Forgot.InnerContent>
          </Forgot.Content>
        </Forgot.Container>
      </Forgot>
      <FooterContainer />

    </Fragment>
  );
};

export default Forgott;
