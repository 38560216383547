import { AnnouncementsActionType, GET_ANNOUNECEMENTS } from "../constants/AnnouncementConstants";
import { Announcements } from "../types/types";

const initStateAnnouncements:Announcements={
    announcements:[]
}

export const announcementsListReducer = (state =initStateAnnouncements, action:AnnouncementsActionType) => {
    switch (action.type) {
      case GET_ANNOUNECEMENTS:
        return {...state,
            announcements: action.payload };
      default:
        return state;
    }
  };