import AXIOS from "../config/Axios";
import Prefix from "../config/ApiPrefix"
import headers from "../helpers/headers";
import { Credentials } from '../redux/types/types';

const Agencies = {
  getAgencies() {
    return  AXIOS.get(`${Prefix.api}/client/Agencies`);
  },
 
};


export default Agencies;