import AXIOS from "../config/Axios";
import Prefix from "../config/ApiPrefix"
import headers from "../helpers/headers";
import { Announcement } from "../redux/types/types";

const AnnouncementService = {
  getAnnouncements() {
    return  AXIOS.get(`${Prefix.api}/client/Announcements`);
  },
  reserveAnnouncement(id:number){
    return  AXIOS.post(`${Prefix.api}/client/Announcements/reserve/${id}`,{},{headers:headers()});

  }

}
export default AnnouncementService;