import AXIOS from "../config/Axios";
import Prefix from "../config/ApiPrefix"
import headers from "../helpers/headers";
import { Credentials } from '../redux/types/types';

const Auth = {
  login(data: Credentials) {
    return  AXIOS.post(`${Prefix.api}/client/login`, data);
  },
   checkLogin() {
    return  AXIOS.post(`${Prefix.api}/client/me`, {},{ headers: headers() });
  },
  resetPassword(data: any){
    return AXIOS.post(`${Prefix.api}/client/resetPassword`,data);
  },
  sendLink(data: any){
    return AXIOS.post(`${Prefix.api}/client/reset`,data);
  },
  logout() {
    return AXIOS.post(`${Prefix.api}/SuperAdmin/logout`, { headers: headers()});
  },
};


export default Auth;