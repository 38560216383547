import AXIOS from "../config/Axios";
import Prefix from "../config/ApiPrefix"
import headers from "../helpers/headers";
import { Credentials } from '../redux/types/types';

const RealEstateService = {
  getAnnouncements() {
    return  AXIOS.get(`${Prefix.api}/client/Announcements`);
  },
  getAnnouncement(id:number) {
    return  AXIOS.get(`${Prefix.api}/client/Announcements/${id}`);
  },
  getAnnouncementRealEstate(id: number) {
    return  AXIOS.get(`${Prefix.api}/client/Announcements/realEstate/${id}`);
  },
  getRealEstates(){
    return  AXIOS.get(`${Prefix.api}/client/RealEstates`);

  }
 
};


export default RealEstateService;