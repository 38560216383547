import axios from 'axios';

const AXIOS = axios.create({
   baseURL: 'http://localhost:8000/',
  headers: {
    'Access-Control-Allow-Credentials':true,

  },
  withCredentials:true,
timeout: 10000,
});

export default AXIOS;