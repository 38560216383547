import { Dispatch } from "redux";
import AnnouncementService from "../../services/Announcement";
import { getAnnouncements } from "../actionCreators/AnnouncementsActionCreators";



export const getAnnouncementsList = () =>async (dispatch:Dispatch) => {
    await AnnouncementService.getAnnouncements().then(res=>{
        dispatch(getAnnouncements(res.data));

    })
    .catch(err=>console.log(err));
  };