import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getPropertyList } from "../redux/actions/propertiesAction";

import { FormWrapper, Form } from "../components";

import { priceFormat } from "../helpers/helper_functions";

const AdvancedSearchContainer = () => {
 /* const dispatch = useDispatch();

  const { properties } = useSelector((state:any) => state.propertyList);

  const price = properties.map(
    (property:any) => +property.price.split(",").join("")
  );


  const categories = [
    ...new Set(properties.map((property:any) => property.category)),
  ];

  const listedIn = [
    ...new Set(properties.map((property:any) => property.listedIn)),
  ];

  const counties = [
    ...new Set(properties.map((property:any) => property.address.county)),
  ];
  const rooms = [
    ...new Set(properties.map((property:any) => property.features.bedrooms)),
  ].sort((a:any, b:any) => a - b);

  const [priceRange, setPriceRange] = useState(0);

  useEffect(() => {
    dispatch(getPropertyList());
  }, [dispatch]);*/


  return (
    <FormWrapper>
      <FormWrapper.Header>
        <FormWrapper.Title>Advanced Search</FormWrapper.Title>
      </FormWrapper.Header>
      <FormWrapper.Content>
        <Form>
         
          <Form.FormGroup>
            <Form.Select>
              <Form.Option defaultValue>Counties</Form.Option>
                <Form.Option key="Monastir">Monastir</Form.Option>
                <Form.Option key="Sousse">Monastir</Form.Option>
                <Form.Option key="Sfax">Sfax</Form.Option>

            </Form.Select>
          </Form.FormGroup>
          <Form.FormGroup>
            <Form.Select>
              <Form.Option defaultValue>House</Form.Option>
                <Form.Option key="House">House</Form.Option>
                <Form.Option key="Villa">Villa</Form.Option>
            </Form.Select>
          </Form.FormGroup>
          <Form.FormGroup>
            <Form.Select>
              <Form.Option defaultValue>Bed Rooms</Form.Option>
                <Form.Option key={Math.random()}>Room1</Form.Option>
              
            </Form.Select>
          </Form.FormGroup>
          <Form.FormGroup>
            <Form.Span>
            
            </Form.Span>
            <Form.RangeInput
              type="range"
            
            />
          </Form.FormGroup>
          <Form.FormGroup>
            <Form.SubmitInput type="submit" value="Search" />
          </Form.FormGroup>
        </Form>
      </FormWrapper.Content>
    </FormWrapper>
  );
};

export default AdvancedSearchContainer;
