import { Dispatch } from "redux";
import AgenciesService from "../../services/Agencies";
import { getAgencies } from "../actionCreators/AgenciesActionCreators";
import { GET_AGENTS } from "../constants/agentsConstants";
import { Agencies } from "../types/types";



export const getAgencyList = () =>async (dispatch:Dispatch) => {
    let agencies
    await AgenciesService.getAgencies().then(res=>{
        agencies=res.data;
        dispatch(getAgencies(res.data));

    })
    .catch(err=>console.log(err));
  };