const links = [
  {
    name: "Home",
    to: "/",
  },
  {
    name: "Listing",
    to: "/listing",
  },
  {
    name: "Agencies",
    to: "/agencies",
  },
 /*{
    name: "login",
    to: "/login",
  },
  {
    name: "logout",
    to: "/logout",
  },
  */
  
];

export default links;
