export type Credentials={
    email:string,
        password:string
}
export type Region={
    id:number,
    code:string,
    region:string
}
export type DataClient={
    cin:string,
    first_name:string,
    last_name:string,
    email:string,
    password:string,
    phone_number:string,
    address:string
    region:string
}
export type Client={
    id:number,
    cin:string,
    first_name:string,
    last_name:string,
    email:string,
    password:string,
    phone_number:string,
    address:string,
    region:Region,
    image:string
}

export interface RealEstate{
    id:number
    name:string,
    address:string,
    surface:number,
    category:string,
    price:number,
    agency:Agency,
    corporate_id?:number,
    lat:number,
    lng:number 
        
}
export interface Announcement{
    id:number
    title:string,
    image:string,
    image1:string,
    image2:string,
    image3:string,
    image4:string,

    body:string,
    reserved:boolean
    real_estate:RealEstate
}
export interface Announcements{
    announcements:Announcement[]
}
export interface Agency{
    id:number,
name:string,
region_id:number,
localization:string,
address:string,
phone_number:number,
description:string,
image:string,
email:string



}
export interface Agencies{
    agencies:Agency[]
}
type PointGeometry = {
    type: "Point";
    coordinates: [number, number];
  };
  
  // Point Property là props nhận trong mrkerComponent
  export type PointProperty = {
    id: number;
    [key: string]: any;
  };
  
  export type Point = {
    type: "Feature";
    id: string;
    geometry: PointGeometry;
    properties: PointProperty;
  };
  
  export const data: Point[] = [];
  
export const TOKEN='token';
export const USER='user';
