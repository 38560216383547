import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getProperty,
  getFeaturedList,
} from "../redux/actions/propertiesAction";
import { Form,Section, Property } from "../components";
import {
  HeaderContainer,
  ContactAgentContainer,
  PropertyRelatedContainer,
  FooterContainer,
} from "../containers";

import {
  PropertGallery,
  PropertyAddress,
  PropertyFeatures,
  PropertyDescription,
} from "../partials/property_features_partial";
import RealEstateServices from "../services/RealEstate";
import { Announcement, Announcements } from "../redux/types/types";
import { RootState } from "../redux/store";
import AnnouncementService from "../services/Announcement";
import Map from "../components/Map/Map";
interface PrameProps{
  id:string
}
const Listing = () => {
  
  const {id}:PrameProps = useParams<PrameProps>();

  const dispatch = useDispatch();
  
  const [announcement,setAnnouncement]=useState<Announcement>();


  const announcements:Announcements = useSelector((state:RootState) => state.announcements);
  const list=announcements.announcements.filter((announcement:Announcement)=>{return announcement.id===parseInt(id)})


  // To display featured properties except one with the id


 /* useEffect(() => {
    dispatch(getProperty(id));
    dispatch(getFeaturedList());
  }, [dispatch, id]);*/
   useEffect(() => {
    console.log(list)
    setAnnouncement(list[0]);
     console.log(announcement);
  }, 
  
  
  );
  const reserveAnnouncement=async(id:number)=>{
    await AnnouncementService.reserveAnnouncement(id).
    then(res=>console.log(res))
    .catch(err=>console.log(err))
  }

  // @ts-ignore
  // @ts-ignore
  return(
    <>
      <HeaderContainer bg="false" />
      <Section bgColor="--bs-fade-info">
        <Section.InnerContainer>
          <Property.Header>
            <Property.HeaderLeft>
              <Property.Title>{announcement?.title}</Property.Title>
              <Property.Location>
                <Property.Icon name="fas fa-map-marker-alt"></Property.Icon>
                <Property.Text>{announcement?.real_estate?.address}</Property.Text>
              </Property.Location>
            </Property.HeaderLeft>
            <Property.HeaderRight>
              <Property.Title>
                Ksh {"   "}
                {announcement?.real_estate?.price}
                <Property.Span>
                  {announcement?.real_estate?.category }
                </Property.Span>
              </Property.Title>
            </Property.HeaderRight>
          </Property.Header>
          {announcement?
          <Property.Content>
            <Property.Left>
              <PropertGallery announcement={announcement}  />
              <Map lat={announcement?.real_estate.lat} lng={announcement?.real_estate.lng} name={announcement?.real_estate.name}   />
              <PropertyDescription description={announcement?.body} />
              <Form.SubmitInput type="submit" value="Reserve" onClick={()=>reserveAnnouncement(parseInt(id))} />
            </Property.Left>
           
          </Property.Content>:<></>}
        </Section.InnerContainer>
      </Section>
      <FooterContainer />
      </>
  )

}
export default Listing;
