import Auth from "../../services/Auth";
import {Client, Credentials, TOKEN, USER} from "../types/types";
import {Dispatch} from "redux";
import jwt from 'jwt-decode'
import {setUser, setLoading, logout, setError} from "../actionCreators/authActionCreators";
import {LOGIN_LOGOUT} from "../constants/authConstants";
import { Data } from "../../components/table/styles/table";
import Clients from "../../services/Clients";
export const login=({email, password}:Credentials) =>
      async (dispatch:Dispatch) => {

          try {
            dispatch(setError(""));
                dispatch(setLoading(true));
              const response = await Auth.login({email, password}).then((res:any)=>{
                dispatch(setError(""));
                dispatch(setLoading(false));

                localStorage.setItem(TOKEN, res.data.access_token);
                // eslint-disable-next-line no-restricted-globals
                console.log(res.data);
                dispatch(setUser(res.data.user));
                localStorage.setItem(USER, JSON.stringify(res.data.user));
              }).catch((err:string)=>{
                dispatch(setLoading(false));

                dispatch(setError("Bad Credentials"));


                throw err;
              })
             


          } catch (err) {

              dispatch(setLoading(false));
              dispatch(setError("Bad Credentials"));


              throw err;

          }
      }

   
export const updateProfile=(client:Client,file:File)=>
async (dispatch:Dispatch) => {
    // here image is url/location of image 
    let fd=new FormData();
    fd.append('cin',client.cin);
    fd.append('first_name',client.first_name);
    fd.append('last_name',client.last_name);
    fd.append('email',client.email);
    fd.append('password',client.password);
    fd.append('image',file)
    fd.append('address',client.address);
    fd.append('phone_number',client.phone_number)
    fd.append('region',client.region.region)
  Clients.updateProfile(fd ,client.id).then((res:any)=>{
     console.log(res.data);
})
.catch((err:any)=>{
    throw err;
  })
   Clients.getProfile(client.id).then((res:any)=>{
    dispatch(setUser(res.data));
    localStorage.setItem(USER, JSON.stringify(res.data));


  })
  .catch(err=>{throw err})
}
export const getProfile=async(id:number)=>
async (dispatch:Dispatch) => {
    await Clients.getProfile(id).then((res:any)=>{
        console.log(res.data)
         dispatch(setUser(res.data));

}).catch((err:any)=>{
    throw err;
  })
}

/*export const login =  (body: Credentials)=>async( dispatch:Dispatch<ActionType>)=> {
    dispatch({ type: LOGIN_SET_LOADING, payload: true })
    try {
        const response = await Auth.login(body);
        console.log(response);
        const { data, status } = response;
        if (status === 200 ) {
            localStorage.setItem(TOKEN, data.access_token);
            }

    } catch (error:any) {
        dispatch({ type: LOGIN_SET_LOADING, payload: false })
        throw error.response;
    }
};

/*export const checkUser =  ()=>async (dispatch:Dispatch<ActionType>)=>{
    try {
        const {
            data,
            status
        } = await Auth.checkLogin();
        console.log(data);
        if(status===200) {
            dispatch({type: LOGIN_SET_USER, payload: data})
            return data;
        }
    } catch (error) {
        throw error;
    }
};*/

export const logoutSession=()=>
 (dispatch:Dispatch) => {
     dispatch(logout());
 }