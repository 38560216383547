import React, { useState } from "react";
import SearchInput from "../components/SearchBar/SearchInput";
import { ListingItemContainer } from "../containers";
import { genericSearch } from "../utils/genericSearch";
interface Props{
    elements:any[]
}

export default function SearchResult(props:Props) {
  const [query, setQuery] = useState<string>("");


  const resultWidgets = props.elements
    .filter((element) =>
      genericSearch<any>(element,["title"], query)
    );
    

  return (

    <div className="container mx-auto my-2">
            <SearchInput onChangeSearchQuery={(query) => setQuery(query)} />
      {resultWidgets.length > 0 && (
        <div className="row">
          {resultWidgets.map((widget) => (
            <ListingItemContainer key={widget.id} announcement={widget} />
          ))}
        </div>
      )}
      {resultWidgets.length === 0 && <p>No results found!</p>}
    </div>
  );
}