import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

import thunk from "redux-thunk";

import {
  propertyListReducer,
  featuredPropertyReducer,
  propertyReducer,
} from "./reducers/propertiesReducer";

import {
  featuredAgentsReducer,
  agentListReducer,
  agentReducer,
} from "./reducers/agentsReducer";
import { messagesReducer } from "./reducers/messagesReducer";
import {authReducer} from "./reducers/authReducer";
import { agencyListReducer } from "./reducers/AgencyReducers";
import { announcementsListReducer } from "./reducers/AnnouncementsReducers";
const initialState={};
const middlewares = [thunk];

const reducer = combineReducers({
  propertyList: propertyListReducer,
  featuredProperty: featuredPropertyReducer,
  featuredAgents: featuredAgentsReducer,
  agentList: agentListReducer,
  agencyList:agencyListReducer,
  auth:authReducer,
  agent: agentReducer,
  property: propertyReducer,
  messageList: messagesReducer,
  announcements:announcementsListReducer
});

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middlewares))
);
export type RootState = ReturnType<typeof store.getState>

export default store;
