import React, { Fragment, useState } from "react";
import { HeaderContainer, FooterContainer } from "../containers";
import { Signup, Form } from "../components";
import Clients from "../services/Clients";
import {Alert, Snackbar, SnackbarContent} from "@mui/material";
import Auth from "../services/Auth";
import {useHistory} from "react-router-dom";
import {Credentials, DataClient} from "../redux/types/types";

type Errors={
    cin:string,
        first_name:string,
        last_name:string,
        email:string,
        password:string,
        phone_number:string,
        address:string
    region:string
}
const initialState:Errors={
    cin:"",
    first_name:"",
    last_name:"",
    email:"",
    password:"",
    phone_number:"",
    address:"",
    region:""
}
const initialError:DataClient={
    cin:"",
    first_name:"",
    last_name:"",
    email:"",
    password:"",
    phone_number:"",
    address:"",
    region:""
}


const Signupp = () => {
    const history=useHistory();
const [client,setClient]=useState<DataClient>(initialState);
const [formErrors,setFormErrors]=useState<Errors>(initialError)
    const [open, setOpen] = React.useState(false);
    const handleToClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if ("clickaway" == reason) return;
        setOpen(false);
    };

    const handleClickEvent = () => {
        setOpen(true);
    };



    const handleChange=(e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>{
        setClient({...client,[e.target.name]:e.target.value})
    }

     const handleSubmit=async(e:React.ChangeEvent<HTMLInputElement>)=>{
         e.preventDefault();
         console.log(client);
         setFormErrors(validate(client));
         try {
             const response = await Clients.register(client);
             const {data, status} = response
             console.log(data);
             handleClickEvent();
             console.log(data.data.email)
             const email=data.data.email;
             await Auth.sendLink({email});
             history.push('/');
         } catch(err:any){
             throw err;

     } }
    const validate = (values:DataClient) => {
        let errors :Errors= {
            cin:"",
            first_name:" ",
            last_name:" ",
            email:" ",
            password:" ",
            phone_number:"",
            address:"",
            region:" "
        };
        if (values.cin==="") {
            errors.cin = "Cannot be blank";
        }
        if (values.first_name==="") {
            errors.first_name = "Cannot be blank";
        }
        if (values.last_name==="") {
            errors.last_name = "Cannot be blank";
        }
        if (values.phone_number==="") {
            errors.phone_number = "Cannot be blank";
        }
        if (values.address==="") {
            errors.address = "Cannot be blank";
        }
        if (values.region==="") {
            errors.region = "Cannot be blank";
        }
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

        if (values.email==="") {
            errors.email = "Cannot be blank";
        } else if (!regex.test(values.email)) {
            errors.email = "Invalid email format";
        }
        if (values.password==="") {
            errors.password = "Cannot be blank";
        } else if (values.password.length < 4) {
            errors.password = "Password must be more than 4 characters";
        }
        console.log(errors);
        return errors;
    };
    return (
        <Fragment>
            <HeaderContainer bg="false" />
            <Signup>
                <Signup.Container>
                    <Signup.Content>
                        <Signup.Header>
                            <Signup.Title>Signup</Signup.Title>
                        </Signup.Header>
                        <Signup.InnerContent>
                            <Form onSubmit={(e:any)=>handleSubmit(e)}>
                                <Form.FormGroup>
                                    <Form.Label>Cin</Form.Label>
                                    <Form.Input name='cin' value={client.cin} onChange={handleChange} type="text" />
                                </Form.FormGroup>
                                {formErrors.cin && (
                                    <span style={{color:"red",fontWeight:"bold"}}>{formErrors.cin}</span>
                                )}
                                <Form.FormGroup>
                                    <Form.Label>FirstName</Form.Label>
                                    <Form.Input name='first_name' value={client.first_name} onChange={handleChange} type="text" />
                                </Form.FormGroup>
                                {formErrors.first_name && (
                                    <span style={{color:"red",fontWeight:"bold"}}>{formErrors.first_name}</span>
                                )}
                                <Form.FormGroup>
                                    <Form.Label>LastName</Form.Label>
                                    <Form.Input name='last_name' value={client.last_name} onChange={handleChange} type="text" />
                                </Form.FormGroup>
                                {formErrors.last_name && (
                                    <span style={{color:"red",fontWeight:"bold"}}>{formErrors.last_name}</span>
                                )}
                                <Form.FormGroup>
                                    <Form.Label>Email</Form.Label>
                                    <Form.Input name='email' value={client.email} onChange={handleChange} type="text" />
                                </Form.FormGroup>
                                {formErrors.email && (
                                    <span style={{color:"red",fontWeight:"bold"}}>{formErrors.email}</span>
                                )}
                                <Form.FormGroup>
                                    <Form.Label>Password</Form.Label>
                                    <Form.Input name='password' value={client.password} onChange={handleChange} type="password" />
                                </Form.FormGroup>
                                {formErrors.password && (
                                    <span style={{color:"red",fontWeight:"bold"}}>{formErrors.password}</span>
                                )}
                                <Form.FormGroup>
                                    <Form.Label>PhoneNumber</Form.Label>
                                    <Form.Input name='phone_number' value={client.phone_number} onChange={handleChange} type="text" />
                                </Form.FormGroup>
                                {formErrors.phone_number && (
                                    <span style={{color:"red",fontWeight:"bold"}}>{formErrors.phone_number}</span>
                                )}
                                <Form.FormGroup>
                                    <Form.Label>Address</Form.Label>
                                    <Form.Input name='address' value={client.address} onChange={handleChange} type="text" />
                                </Form.FormGroup>
                                {formErrors.address && (
                                    <span style={{color:"red",fontWeight:"bold"}}>{formErrors.address}</span>
                                )}
                                <Form.FormGroup>
                                    <Form.Label>Region</Form.Label>
                                    <Form.Input name='region' value={client.region} onChange={handleChange}  type="text" />
                                </Form.FormGroup>
                                {formErrors.region && (
                                    <span style={{color:"red",fontWeight:"bold"}}>{formErrors.region}</span>
                                )}
                                <Form.FormGroup>
                                    <Form.SubmitInput type="submit" value="Signup"  />
                                </Form.FormGroup>
                            </Form>
                        </Signup.InnerContent>
                        <Signup.Footer>
                            <Signup.Text>
                                Already Have Account ?{" "}
                                <Signup.Anchor to="/login">Login</Signup.Anchor>
                            </Signup.Text>
                        </Signup.Footer>
                    </Signup.Content>
                </Signup.Container>
            </Signup>
            <FooterContainer />
            <Snackbar open={open}
                      autoHideDuration={6000}
                      onClose={handleToClose}>
                <Alert onClose={handleToClose} severity="success" sx={{ width: '100%' }}>
                    Registred Successfully!
                </Alert>


            </Snackbar>
        </Fragment>
    );
};

export default Signupp;
